import React from 'react';
import { Link, graphql } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';
import Img from 'gatsby-image';
import Layout from '../components/Layout/Layout';
import Button from '../UI/Button';
import '../styles/index.scss';

const BlogIndexTemplate = ({ data, ...props }) => {
    const getLink = (slug, title) => {
        return (
            <Link to={`/recetas/${slug}`} className="continue-reading">
                <h3 className="cfp-content-title is-marginless" dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
        );
    };
    const getImage = ({ portada, updatedAt, slug }) =>
        portada && (
            <div className="post-image-container">
                <Link to={`/recetas/${slug}`}>
                    <div className="created-at">{updatedAt}</div>
                    <Img className="post-image" sizes={portada.sizes} alt="Imagen" />
                </Link>
            </div>
        );
    const { edges } = data.allContentfulRecetas || { edges: {} };
    return (
        <Layout title="Recetas" {...props}>
            <section className="gray">
                <div className="child cfp-container cfp-container-blog inner">
                    {edges.length > 0 &&
                        edges.map(({ node }, index) => {
                            const title = node.titulo.childMarkdownRemark.html || node.slug;
                            const body = node.cuerpo.childMarkdownRemark.excerpt;
                            return (
                                <ScrollAnimation animateIn="zoomIn" animateOnce={true} className="article" key={index}>
                                    <article key={node.slug}>
                                        {getImage(node)}
                                        <div className="post-content">
                                            {getLink(node.slug, title)}
                                            <div
                                                className="blog-post-content text"
                                                dangerouslySetInnerHTML={{ __html: body }}
                                            />
                                            <Button initiallyVisible={true} to={`/recetas/${node.slug}`} text="Leer" />
                                        </div>
                                    </article>
                                </ScrollAnimation>
                            );
                        })}
                    {edges.length <= 0 && <div>No hay publicaciones disponibles</div>}
                </div>

                <div className="rectangle-top-right">
                    <ScrollAnimation animateIn="slideInRight" animateOnce={true}>
                        <img src="/rectangle.svg" alt="Rectangle" className="rectangle-img" />
                    </ScrollAnimation>
                </div>
            </section>
        </Layout>
    );
};

export default BlogIndexTemplate;

export const query = graphql`
           query BlogRecipesPageQuery {
               allContentfulRecetas(sort: { fields: updatedAt, order: DESC }) {
                   edges {
                       node {
                           id
                           titulo {
                               childMarkdownRemark {
                                   html
                               }
                           }
                           slug
                           cuerpo {
                               childMarkdownRemark {
                                   excerpt
                               }
                           }
                           portada {
                               sizes(maxWidth: 1280) {
                                   ...GatsbyContentfulSizes
                               }
                           }
                           updatedAt(formatString: "DD / MM")
                       }
                   }
               }
           }
       `;
